import React from 'react'
import "normalize.css"
import Footer from './components/footer'
import Head from '../pages/components/head'
import Contact from './components/contact'
import Testimonials from './components/testimonials'
import Projects from './components/projects'
import Skills from './components/skills'
import About from './components/about'
import IndexHeader from './components/index-header'

const IndexPage = () => {
    return (
        <div>
            <Head title="Home"/>
            <IndexHeader />
            <About />
            <Skills />
            <Projects />
            <Testimonials />
            <Contact />
            <Footer />
        </div>
    )
}
export default IndexPage



