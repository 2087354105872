import React, { Component } from 'react'
import "normalize.css"
import contactStyles from './sass/contact.module.scss'
import { navigateTo } from 'gatsby';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            redirect: false
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": form.getAttribute("name"), ...this.state })
        })
        .then(() => navigateTo(form.getAttribute("action")))
        .catch(error => alert(error));
        
    };
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value});
    }
    render() {
        const { name, email, message} = this.state;
        return (
            <div className={contactStyles.sectionContact}>
            <div className={contactStyles.contact}>
                <div className={contactStyles.contactForm}>
                    <form onSubmit={this.handleSubmit} action="/success" name="contact" >
                        <input type="hidden" name="form-name" value="contact" />
                        <h2 className={[contactStyles.headingSecondary, contactStyles.headingForm].join(' ')}>
                            Contact me
                        </h2>
                        <div className={contactStyles.formGroup}>
                            <input type="text" className={contactStyles.formInput} value={name} onChange={this.handleChange} name="name" placeholder="Full name" id="name" required></input>
                            <label htmlFor="name" className={contactStyles.formLabel}>Full name</label>
                        </div>

                        <div className={contactStyles.formGroup}>
                            <input type="email" className={contactStyles.formInput} value={email} onChange={this.handleChange} name="email" placeholder="Email" id="email" required></input>
                            <label htmlFor="email" className={contactStyles.formLabel}>Email</label>
                        </div>

                        <div className={contactStyles.formGroup}>
                            <label>
                                <textarea className={contactStyles.formInput} name="message" placeholder="Enter text here!" value={message} onChange={this.handleChange} id="contact" required></textarea>
                            </label>
                        </div>
                        
                        <div className={contactStyles.formGroup}>  
                            <button type="submit" className={[contactStyles.btn, contactStyles.btnWhite, contactStyles.btnForm].join(' ')}>Next step &rarr;</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )  
    }
}

export default Contact
