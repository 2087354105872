import React from 'react'
import "normalize.css"
 import { Link, graphql, useStaticQuery } from 'gatsby'
import aboutStyles from './sass/about.module.scss'

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    subtitle
                    homeDescription
                    author
                    position
                    introduction
                }
            }
        }
    `)
    return(
        <div className={aboutStyles.sectionAbout}>
            <div className={[aboutStyles.centerText, aboutStyles.marginBottom].join(' ')}>
                <h2 className={aboutStyles.headingSecondary}>
                    Get to know me!
                </h2>
            </div>
            <div className={aboutStyles.row}>
                    <div className={aboutStyles.col1}>
                    <h3 className={aboutStyles.headingTertiary}>{data.site.siteMetadata.author}</h3>
                    <h4 className={aboutStyles.headingTertiary}>{data.site.siteMetadata.position}</h4>
                    <p>{data.site.siteMetadata.homeDescription} I write about softwate development on my <Link to="/blog">blog</Link>. Want to know how i may help your project? check out my project <Link>portfolio</Link> and <Link>online resume</Link>.</p>
                    <Link to={"/about"} className={aboutStyles.buttonText}>Learn more &rarr;</Link>
                    <h4>What i do ?</h4>  
                    <p>I have more than 2 years' experience building software. Below is a quick overview of my main technical skill sets and technologies i use. Want to find out more about my experience? Check out my <Link>online resume</Link> and project <Link>portfolio</Link>.</p>
                </div>
                    <div className={aboutStyles.col2}>                         
                        <div className={aboutStyles.composition}>
                            <img src={require("../posts/assets/me-1.jpg")} alt="photo1" className={[aboutStyles.compositionPhoto, aboutStyles.compositionPhoto1].join(' ')}/>
                            <img src={require("../posts/assets/me-2.png")} alt="photo2" className={[aboutStyles.compositionPhoto, aboutStyles.compositionPhoto2].join(' ')}/>
                            <img src={require("../posts/assets/me.jpg")} alt="photo3" className={[aboutStyles.compositionPhoto, aboutStyles.compositionPhoto3].join(' ')}/>
                        </div>         
                    </div>
                </div>
        </div>
    )
}

export default About